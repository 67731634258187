import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {makeValidate, TextField} from 'mui-rff';
import React from 'react';
import {Form} from 'react-final-form';
import {useHistory} from 'react-router';
import * as yup from 'yup';
import Layout from '../../components/Layout';

const schema = yup.object({
    query: yup.string().label('Keywords').trim().notRequired(),
    zipCode: yup.string().label('ZIP code')
        .matches(/^\d{5}(?:-\d{4})?$/, {message: 'Must be a valid ZIP code'})
        .notRequired(),
    distance: yup.number().label('Distance').min(1).notRequired().default(25),
}).required();

const validate = makeValidate(schema);

const SearchForm : React.FC = () => {
    const history = useHistory();

    const onSubmit = (values : yup.Asserts<typeof schema>) => {
        const formData = schema.cast(values);
        const searchParams = new URLSearchParams();

        if (formData.query) {
            searchParams.set('query', formData.query);
        }

        if (formData.zipCode) {
            searchParams.set('zipCode', formData.zipCode);
            searchParams.set('distance', formData.distance.toString());
        }

        history.push({
            pathname: '/search',
            search: searchParams.toString(),
        });
    }

    return (
        <Layout title="Job Search">
            <Form
                onSubmit={onSubmit}
                initialValues={{distance: 25}}
                validate={validate}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Keywords"
                                    name="query"
                                    variant="outlined"
                                    helperText={
                                        'Choose up to three keywords.'
                                        + ' They more keywords you choose, the more narrow your search will be.'
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="ZIP code"
                                    name="zipCode"
                                    variant="outlined"
                                    inputProps={{maxLength: 10}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Distance"
                                    name="distance"
                                    variant="outlined"
                                    select
                                >
                                    <MenuItem value={25}>Within 25 Miles</MenuItem>
                                    <MenuItem value={50}>Within 50 Miles</MenuItem>
                                    <MenuItem value={100}>Within 100 Miles</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" color="primary" variant="contained">Search</Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </Layout>
    );
};

export default SearchForm;
