import {useMediaQuery} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import {useTheme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    summary: {
        marginBottom: theme.spacing(2),
    },
    preFormatted: {
        whiteSpace: 'pre-line',
    },
}));

type RouteParams = {
    jobId : string;
};

type Job = {
    id : string;
    division : string;
    title : string;
    level : string;
    location : string;
    duration : string;
    education : string;
    description : string;
    requirement : string;
    payRate ?: string;
    recruiter : {
        name : string;
        emailAddress : string;
        phoneNumber : string;
    };
};

type Props = {
    parentPath : string;
};

const Job : React.FC<Props> = ({parentPath} : Props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {jobId} = useParams<RouteParams>();
    const [job, setJob] = useState<Job | null>(null);

    const handleClose = () => history.push(`${parentPath}${history.location.search}`);

    useEffect(() => {
        let canceled = false;

        (async () => {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/jobs/${jobId}`);

            if (!response.ok) {
                enqueueSnackbar('Could not load job entry', {variant: 'error'});
                handleClose();
                return;
            }

            const job : Job = await response.json();

            if (!canceled) {
                setJob(job);
            }
        })();

        return () => {
            canceled = true;
        };
    }, [jobId, enqueueSnackbar]);

    return (
        <>
            {!job ? (
                <Backdrop open className={classes.backdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            ) : (
                <Dialog
                    open
                    onClose={handleClose}
                    maxWidth="md"
                    fullWidth
                    fullScreen={fullScreen}
                    transitionDuration={0}
                >
                    <DialogTitle disableTypography className={classes.dialogTitle}>
                        <Typography variant="h6">{job.title}</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container component="dl" spacing={2} className={classes.summary}>
                            <Grid item xs={12}>
                                <Typography component='dt' variant='subtitle2'>ATR ID:</Typography>
                                <Typography component='dd' variant='body2'>{job.id}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='dt' variant='subtitle2'>Job Title:</Typography>
                                <Typography component='dd' variant='body2'>{job.title}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='dt' variant='subtitle2'>Job Level:</Typography>
                                <Typography component='dd' variant='body2'>{job.level}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='dt' variant='subtitle2'>Duration:</Typography>
                                <Typography component='dd' variant='body2'>{job.duration}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='dt' variant='subtitle2'>Location:</Typography>
                                <Typography component='dd' variant='body2'>{job.location}</Typography>
                            </Grid>
                            {job.education && (
                                <Grid item xs={12}>
                                    <Typography component='dt' variant='subtitle2'>Education:</Typography>
                                    <Typography component='dd' variant='body2'>{job.education}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography component='dt' variant='subtitle2'>Email:</Typography>
                                <Typography component='dd' variant='body2'>
                                    <Link href={
                                        `mailto:${job.recruiter.emailAddress}?subject=ATR WEBSITE APPLICATION ${job.id}`
                                    }>
                                        {job.recruiter.name}
                                    </Link>
                                </Typography>
                            </Grid>
                            {job.recruiter.phoneNumber && (
                                <Grid item xs={12}>
                                    <Typography component='dt' variant='subtitle2'>Phone:</Typography>
                                    <Typography component='dd' variant='body2'>{job.recruiter.phoneNumber}</Typography>
                                </Grid>
                            )}
                            {job.payRate && (
                                <Grid item xs={12}>
                                    <Typography component='dt' variant='subtitle2'>Pay rate:</Typography>
                                    <Typography component='dd' variant='body2'>{job.payRate}</Typography>
                                </Grid>
                            )}
                        </Grid>

                        <Typography variant="subtitle2">Job Description:</Typography>
                        <Typography paragraph className={classes.preFormatted}>{job.description}</Typography>

                        {job.requirement && (
                            <>
                                <Typography variant="subtitle2">Requirement:</Typography>
                                <Typography paragraph className={classes.preFormatted}>{job.requirement}</Typography>
                            </>
                        )}

                        <Typography variant="subtitle2">How to Apply:</Typography>
                        <Typography paragraph>
                            If this position is a fit, simply click on the recruiter's email above, attach your resume,
                            and hit send.
                        </Typography>
                        <Typography paragraph>
                            If this position isn't quite right,
                            {' '}<Link href="mailto:resume@atr1.com?subject=ATR WEBSITE APPLICATION">click here</Link>
                            {' '}to let us know what you are looking for and our recruiters will get to work finding
                            something that is a better match.
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </>

    );
};

export default Job;
