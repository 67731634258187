import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LocationIcon from '@material-ui/icons/LocationOn';
import {useSnackbar} from 'notistack';
import React, {useEffect, useMemo, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useHistory, useRouteMatch} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import Layout from '../../components/Layout';
import Job from './Job';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(2),
    },
    loadingIndicator: {
        textAlign: 'center',
    },
    listItem: {
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
    },
    location: {
        display: 'flex',
        alignItems: 'center',
    },
    locationIcon: {
        marginLeft: -2,
    },
}));

type SearchItem = {
    id : string;
    division : string;
    location : string;
    title : string;
};

type Index = {
    totalHits : number;
    hits : SearchItem[];
    next : string | null;
};

const sortByFields = {
    relevance: 'Relevance',
    title: 'Title',
    id: 'Job ID',
    division: 'Division',
    location: 'Location',
};

const SearchResults : React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const {enqueueSnackbar} = useSnackbar();
    const [items, setItems] = useState<SearchItem[] | null>(null);
    const [nextSearch, setNextSearch] = useState<string | null>(null);

    const searchParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
    const sortBy = searchParams.get('sortBy') || 'relevance';

    useEffect(() => {
        let canceled = false;
        setItems(null);

        (async () => {
            const response = await fetch(
                `${process.env.REACT_APP_API_ENDPOINT}/jobs/search?${searchParams.toString()}`
            );

            if (!response.ok) {
                enqueueSnackbar('Could not perform search', {variant: 'error'});
                return history.push('/');
            }

            const data : Index = await response.json();

            if (!canceled) {
                setNextSearch(data.next);
                setItems(data.hits);
            }
        })();

        return () => {
            canceled = true;
        };
    }, [searchParams, history, enqueueSnackbar]);

    const handleLoadNext = async () => {
        if (!nextSearch || !items) {
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}${nextSearch}`);
        const data : Index = await response.json();

        setNextSearch(data.next);
        setItems(items.concat(items, data.hits));
    };

    return (
        <Layout title="Search Results">
            <Grid container className={classes.header} alignItems="flex-end" spacing={2}>
                <Grid item xs>
                    <Button onClick={() => history.push('/')} variant="contained" color="primary">New search</Button>
                </Grid>
                <Grid item>
                    <TextField
                        label="Sort by"
                        value={sortBy}
                        variant="outlined"
                        size="small"
                        select
                        onChange={event => {
                            const newSearchParams = new URLSearchParams(searchParams);
                            newSearchParams.set('sortBy', event.target.value as string);

                            history.push({
                                pathname: routeMatch.path,
                                search: `?${newSearchParams.toString()}`,
                            });
                        }}
                    >
                        {Object.entries(sortByFields).map(([value, label]) => (
                            <MenuItem key={value} value={value}>{label}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        {searchParams.has('query')
                            ? `Showing results matching "${searchParams.get('query')}"`
                            : 'Showing all results'
                        }

                        {searchParams.has('zipCode')
                            && ` within ${searchParams.get('distance') || 25} miles of ${searchParams.get('zipCode')}`
                        }
                    </Typography>
                </Grid>
            </Grid>

            <Divider/>

            {!items ? (
                <div className={classes.loadingIndicator}>
                    <CircularProgress/>
                </div>
            ) : items.length === 0 ? (
                <Typography>Your search did not yield any results.</Typography>
            ) : (
                <List>
                    <InfiniteScroll
                        next={handleLoadNext}
                        hasMore={nextSearch !== null}
                        loader={(
                            <>
                                <Divider component="li"/>
                                <ListItem>
                                    <div className={classes.loadingIndicator}>
                                        <CircularProgress/>
                                    </div>
                                </ListItem>
                            </>
                        )}
                        dataLength={items.length}
                    >
                        {items.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    button
                                    onClick={() => history.push(
                                        `${routeMatch.path}/job/${item.id}${location.search}`
                                    )}
                                    className={classes.listItem}
                                >
                                    <ListItemText
                                        primary={item.title}
                                        secondary={`${item.id} | ${item.division}`}
                                    />
                                    <div className={classes.location}>
                                        <LocationIcon className={classes.locationIcon}/> {item.location}
                                    </div>
                                </ListItem>
                                {index + 1 < items.length && (
                                    <Divider component="li"/>
                                )}
                            </React.Fragment>
                        ))}
                    </InfiniteScroll>
                </List>
            )}

            <Switch>
                <Route
                    path={`${routeMatch.path}/job/:jobId`}
                    render={() => <Job parentPath={routeMatch.path}/>}
                />
            </Switch>
        </Layout>
    );
};

export default SearchResults;
